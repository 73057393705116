@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Review {
  letter-spacing: 0;
  padding: 8px 0;
}
.Review__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    margin-bottom: 8px;
    font-weight: 500;
  }
@media only screen and (max-width: 767px) {
.Review__head {
      margin-bottom: 6px
  }
    }
.Review__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
  }
.Review__avatar-image {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
.Review__info {
    line-height: 1.5;
  }
.Review__title {
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    font-size: 13px;
    line-height: 15px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
.Review__date {
    color: #979797;
    color: var(--medium-gray);
    font-size: 11px;
    font-style: italic;
    text-transform: capitalize;
  }
.Review__rating {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
.Review__text {
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.51);
    color: var(--light-text-gray);
  }
@media only screen and (max-width: 767px) {
.Review__text {
      font-size: 13px
  }
    }
.Review__link {
    display: block;
    color: rgba(0, 122, 255, 1);
    color: var(--blue);
    font-size: 13px;
    font-weight: 500;
    line-height: 150%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 6px;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }
@media (hover: hover) {
      .Review__link:hover {
        color: rgba(0, 0, 0, 0.51);
        color: var(--light-text-gray);
        opacity: 1;
        text-decoration: underline;
      }
        .Review__link:hover .Review__link-icon path {
          stroke: rgba(0, 0, 0, 0.51);
          stroke: var(--light-text-gray);
        }

        .Review__link:hover .Review__link-icon .Icon__book-cover {
          fill: rgba(0, 0, 0, 0.51);
          fill: var(--light-text-gray);
        }
    }
.Review__link-icon {
      display: inline-block;
      float: left;
      margin: 0 3px 0 -2px;
      -webkit-transform: translateY(-1px);
              transform: translateY(-1px);
    }
.Review__link-icon path {
        -webkit-transition: stroke 0.3s ease, fill 0.3s ease;
        transition: stroke 0.3s ease, fill 0.3s ease;
      }
.Review__response-show {
    margin-top: 5px;
    color: #979797;
    color: var(--medium-gray);
  }
.Review__response-show .Button__text {
      letter-spacing: -0.3px;
    }
.Review .TextExpand {
    line-height: 26px;
  }
@media only screen and (max-width: 767px) {
.Review .TextExpand {
      line-height: 1.5
  }
    }
.Review .TextExpand__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.76);
      color: var(--tone-700);
    }
@media only screen and (max-width: 767px) {
.Review .TextExpand__text {
        font-size: 13px;
        line-height: 140%
    }
      }
.Review .TextExpand__link {
      margin-top: 0;
      color: rgba(0, 122, 255, 1);
      color: var(--blue);
      font-weight: 500;
    }
.Review--light-card {
  height: 100%;
  border: 1px solid #e7eafb;
  border: 1px solid var(--light-main);
  border-radius: 10px;
  padding: 0 11px 14px 10px;
}
.Review--light-card .Review__head {
      margin-bottom: 8px;
    }
.Review--light-card .Review__text {
      padding-left: 2px;
    }
.Review--lite {
  position: relative;
}
@media only screen and (min-width: 992px),only screen and (min-width: 768px) and (max-width: 991px) {
.Review--lite {
    padding-left: 67px
}
  }
.Review--lite .Review__avatar {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 2px;
    }
@media only screen and (max-width: 767px) {
.Review--lite .Review__avatar {
        position: relative
    }
      }
.Review--lite .Review__head {
      margin-bottom: 3px;
    }
@media only screen and (max-width: 767px) {
.Review--lite .Review__head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin: 0 0 7px
    }
      }
.Review--lite .Review__subtitle {
      display: none;
    }
