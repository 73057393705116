@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.ProductsSlider--withArrows {
  position: relative;
  width: auto;
  margin: 0 -20px;
  padding: 0 50px;
}
.ProductsSlider--withArrows .ProductsSlider__arrow {
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      padding: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-transition: -webkit-box-shadow 0.3s ease;
      transition: -webkit-box-shadow 0.3s ease;
      transition: box-shadow 0.3s ease;
      transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
      z-index: 1;
    }
.ProductsSlider--withArrows .ProductsSlider__arrow path {
        -webkit-transition: -webkit-box-shadow 0.3s ease;
        transition: -webkit-box-shadow 0.3s ease;
        transition: box-shadow 0.3s ease;
        transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
      }
.ProductsSlider--withArrows .ProductsSlider__arrow_block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
      }
.ProductsSlider--withArrows .ProductsSlider__arrow:disabled {
        background: none;
        opacity: 0.3;
      }
@media (hover:hover) {
          .ProductsSlider--withArrows .ProductsSlider__arrow:hover path {
            stroke: #979797;
            stroke: var(--medium-gray);
          }
      }
.ProductsSlider--withArrows .ProductsSlider__arrow .Button__icon {
        margin: 0;
      }
.ProductsSlider--withArrows .ProductsSlider__arrow--left {
      left: 7px;
    }
.ProductsSlider--withArrows .ProductsSlider__arrow--left .Button__icon {
        -webkit-transform: translateX(-1px);
                transform: translateX(-1px);
      }
.ProductsSlider--withArrows .ProductsSlider__arrow--right {
      right: 7px;
    }
.ProductsSlider--withArrows .ProductsSlider__arrow--right .Button__icon {
        -webkit-transform: translateX(1px);
                transform: translateX(1px);
      }
.ProductsSlider--withArrows.ProductsSlider--flat {
    margin: 0;
  }
@media only screen and (max-width: 767px),only screen and (min-width: 768px) and (max-width: 991px) {
.ProductsSlider--withArrows.ProductsSlider--flat .ProductsSlider__arrow {
        display: none
    }
      }
@media only screen and (max-width: 767px) {
.ProductsSlider--withArrows.ProductsSlider--flat {
      padding: 0
  }
    }
.ProductsSlider--withArrows .BrainhubCarousel__container {
    width: calc(100% + 2 * 8px);
    width: calc(100% + 2 * var(--product-margin));
  }
.ProductsSlider--withArrows.ProductsSlider--arrowsMini {
  margin: 0;
  padding: 0;
}
.ProductsSlider--withArrows.ProductsSlider--arrowsMini .ProductsSlider__arrow--left {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: none;
        padding: 0;
      }
.ProductsSlider--withArrows.ProductsSlider--arrowsMini .ProductsSlider__arrow--right {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: none;
        padding: 0;
      }
.ProductsSlider--withArrows.ProductsSlider--simple {
  margin: 0;
}
.ProductsSlider--withArrows.ProductsSlider--simple .ProductsSlider__arrow {
      background: none;
    }
.ProductsSlider--withArrows.ProductsSlider--simple .ProductsSlider__arrow:disabled {
        opacity: 1;
      }
.ProductsSlider--withArrows.ProductsSlider--simple .ProductsSlider__arrow:disabled path {
          stroke: #ebebeb;
          stroke: var(--disabled-gray);
        }
.ProductsSlider--withArrows.ProductsSlider--simple .ProductsSlider__arrow path {
        stroke: #333333;
        stroke: var(--dark-gray);
        stroke-width: 1.5;
        -webkit-transition: stroke 0.3s ease;
        transition: stroke 0.3s ease;
      }
@media (hover:hover) {
        .ProductsSlider--withArrows.ProductsSlider--simple .ProductsSlider__arrow:hover {
          -webkit-box-shadow: unset;
                  box-shadow: unset;
        }

          .ProductsSlider--withArrows.ProductsSlider--simple .ProductsSlider__arrow:hover path {
            stroke: #979797;
            stroke: var(--medium-gray);
          }
      }
.ProductsSlider--withArrows.ProductsSlider--simple .ProductsSlider__arrow--left {
        left: 14px;
      }
.ProductsSlider--withArrows.ProductsSlider--simple .ProductsSlider__arrow--right {
        right: 14px;
      }
.ProductsSlider--withArrows.ProductsSlider--small {
  padding: 0 15px;
}
.ProductsSlider--withArrows.ProductsSlider--small .ProductsSlider__arrow {
      width: 16px;
      height: 16px;
    }
.ProductsSlider--withArrows.ProductsSlider--small .ProductsSlider__arrow path {
        stroke-width: 3;
      }
.ProductsSlider--withArrows.ProductsSlider--small .ProductsSlider__arrow--left {
        left: 0;
      }
.ProductsSlider--withArrows.ProductsSlider--small .ProductsSlider__arrow--right {
        right: 0;
      }
