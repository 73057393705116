@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Hero {
  position: relative;
}
.Hero__wrapper {
    position: relative;
    padding: 194px 0 100px;
    background-size: cover;
    z-index: 1;
  }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Hero__wrapper {
      padding: 193px 0 80px
  }
    }
@media only screen and (max-width: 767px) {
.Hero__wrapper {
      padding: 64px 0 55px
  }
    }
.Hero__title {
    margin: 0;
    font-weight: bold;
    font-size: 48px;
    line-height: 57px;
    max-width: 744px;
    color: white;
    color: var(--white);
  }
@media only screen and (max-width: 767px) {
.Hero__title {
      font-size: 30px;
      line-height: 36px
  }
    }
.Hero__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;

  }
.Hero__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
.Hero__background::after {
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: -webkit-gradient(linear, left bottom, left top, color-stop(-28.21%, rgba(0, 0, 0, 0.79)), to(rgba(0, 0, 0, 0)));
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.79) -28.21%, rgba(0, 0, 0, 0) 100%);
    }
.Hero__background img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: 100% 40%;
         object-position: 100% 40%;
    }
@media only screen and (min-width: 1600px) {
.Hero__background img {
        -o-object-position: 100% 35%;
           object-position: 100% 35%
    }
      }
.Hero__breadcrumbs {
    position: absolute;
    top: 157px;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 5;
  }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Hero__breadcrumbs {
      padding-bottom: 10px
  }
    }
@media only screen and (max-width: 767px) {
.Hero__breadcrumbs {
      bottom: 0;
      top: unset;
      padding-bottom: 10px
  }
    }
.Hero--no-background .Hero__breadcrumbs {
        position: relative;
        top: 0 !important;
        padding-top: 18px;
      }
.Hero--no-background .Hero__breadcrumbs .Breadcrumbs__item{
            font-size: 12px;
          }
.Hero--no-background .Hero__title {
        font-family: Roboto;
        font-size: 44px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.01em;
        text-align: left;
        color: inherit;
        max-width: 100%;
        margin: 16px 0 8px !important;
      }
@media only screen and (max-width: 767px) {
.Hero--no-background .Hero__title {
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
          text-align: left
      }
        }
.Hero--no-background .Hero__wrapper {
        padding: 0 !important;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
      }
.Hero--thick .Hero__breadcrumbs {
        top: 15px !important;
      }
.Hero--thick .Hero__wrapper {
        height: 500px;
        padding: 0 !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        background: linear-gradient(
          90.39deg,
          rgba(51, 51, 51, 0.7) -40.7%,
          rgba(51, 51, 51, 0) 114.74%
        );
      }
@media only screen and (min-width: 992px) {
          .Hero--thick .Hero__wrapper .Wrapper {
            width: 1300px;
            margin: 0 auto;
          }
        }
.Hero--thick .Hero__background::after {
          background: none !important;
          -webkit-filter: blur(40px);
                  filter: blur(40px);
        }
