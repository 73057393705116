.Placeholder {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
}

  .Placeholder::before {
    content: "";
    display: block;
    height: 200px;
    margin-bottom: 25px;
    background: url("./assets/banner.svg") no-repeat;
    background-size: contain;
    background-position: center;
  }

  @media (--tablet), (--mobile) {

  .Placeholder::before {
      height: 84px;
      margin-bottom: 16px
  }
    }

  @media (--tablet), (--mobile) {

  .Placeholder h1 {
      margin-bottom: 2px
  }
    }
