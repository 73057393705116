.TextExpand__wrapper {
    overflow: hidden;
  }
  .TextExpand__link {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    height: auto;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: var(--blue);

    margin-top: 7px;
    outline: none !important;
    text-decoration: none;
  }
  @media (hover:hover) {
      .TextExpand__link:hover {
        opacity: 0.8;
      }
    }
  .TextExpand--light {
    color: var(--white);
  }
  .TextExpand--origins {
    white-space: pre-wrap;
  }
  .TextExpand__text {
    list-style-position: inside;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: var(--light-text-gray);
  }
  @media (--mobile) {
  .TextExpand__text {
        font-weight: 500;
        font-size: 13px;
        line-height: 140%
  }
      }
