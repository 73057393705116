.ProductsGridAdapter {
  margin-bottom: 24px;
}

  .ProductsGridAdapter .ProductsTabsArrows {
    margin-top: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
