@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.EmailSubscriptionSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #ddd;
    border-radius: 24px;
    height: 260px;
    overflow: hidden;
    font-family: Roboto;
    margin: 0 0 16px 0;
  }
@media only screen and (max-width: 767px) {
.EmailSubscriptionSection {
        height: auto
  }
      }
.EmailSubscriptionSection__status-message {
        left: 0;
        color: #22ccd8;
        color: var(--accent);
        font-size: 11.5px;
        margin-top: -5px;
      }
.EmailSubscriptionSection__status-message-error {
        left: 0;
        color: #ff3b2f;
        color: var(--red);
        font-size: 11.5px;
        margin-top: -5px;
      }
.EmailSubscriptionSection__link {
        color: rgba(0, 106, 219, 1);
        color: var(--link-blue);
      }
@media only screen and (max-width: 767px) {
.EmailSubscriptionSection__link {
          border-bottom: none
      }
        }
.EmailSubscriptionSection__shadow {
        width: 190px;
        height: 15px;
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 71%);
        border-radius: 50%;
        z-index: -1;
      }
.EmailSubscriptionSection__shadow_error {
          margin-left: 30px;
        }
.EmailSubscriptionSection__image-container {
        width: 486px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        text-align: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        position: relative;
        overflow: hidden;
      }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.EmailSubscriptionSection__image-container {
          display: none
      }
        }
.EmailSubscriptionSection__image-container--animating .EmailSubscriptionSection__decorative-image.fade-in {
              -webkit-animation: fadeIn 0.5s ease-out forwards, jellyEffect 0.8s ease-out 0.2s forwards;
                      animation: fadeIn 0.5s ease-out forwards, jellyEffect 0.8s ease-out 0.2s forwards;
            }
.EmailSubscriptionSection__image-container--animating .EmailSubscriptionSection__decorative-image.fade-out {
              -webkit-animation: fadeOut 1s ease-out forwards;
                      animation: fadeOut 1s ease-out forwards;
            }
@-webkit-keyframes fadeIn {
        0% {
          opacity: 0;
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
        }
        100% {
          opacity: 1;
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
@keyframes fadeIn {
        0% {
          opacity: 0;
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
        }
        100% {
          opacity: 1;
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
@-webkit-keyframes fadeOut {
        0% {
          opacity: 1;
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          opacity: 0;
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
        }
      }
@keyframes fadeOut {
        0% {
          opacity: 1;
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          opacity: 0;
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
        }
      }
@-webkit-keyframes jellyEffect {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        20% {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
        }
        40% {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
        }
        60% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
        80% {
          -webkit-transform: scale(0.95);
                  transform: scale(0.95);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
@keyframes jellyEffect {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        20% {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
        }
        40% {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
        }
        60% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
        80% {
          -webkit-transform: scale(0.95);
                  transform: scale(0.95);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
@-webkit-keyframes jellyIn {
        0% {
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
        }
        20% {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
        }
        40% {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
        }
        60% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
        80% {
          -webkit-transform: scale(0.95);
                  transform: scale(0.95);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
@keyframes jellyIn {
        0% {
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
        }
        20% {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
        }
        40% {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
        }
        60% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
        80% {
          -webkit-transform: scale(0.95);
                  transform: scale(0.95);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
@-webkit-keyframes jellyOut {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        20% {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
        }
        40% {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
        }
        60% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
        80% {
          -webkit-transform: scale(0.95);
                  transform: scale(0.95);
        }
        100% {
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
        }
      }
@keyframes jellyOut {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        20% {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
        }
        40% {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
        }
        60% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
        80% {
          -webkit-transform: scale(0.95);
                  transform: scale(0.95);
        }
        100% {
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
        }
      }
@keyframes fadeIn {
        from {
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
          opacity: 0;
        }
        to {
          -webkit-transform: scale(1);
                  transform: scale(1);
          opacity: 1;
        }
      }
@keyframes fadeOut {
        from {
          -webkit-transform: scale(1);
                  transform: scale(1);
          opacity: 1;
        }
        to {
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
          opacity: 0;
        }
      }
.EmailSubscriptionSection__decorative-image {
        -o-object-fit: cover;
           object-fit: cover;
      }
.EmailSubscriptionSection__content-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-flex: 2;
            -ms-flex: 2;
                flex: 2;
        padding: 0 32px;
        background-color: rgba(34, 204, 216, 0.1);
        background-color: var(--aquamarine);
        grid-gap: 8px;
        gap: 8px;
      }
@media only screen and (max-width: 767px) {
.EmailSubscriptionSection__content-container {
            -webkit-box-pack: inherit;
                -ms-flex-pack: inherit;
                    justify-content: inherit;
            padding: 16px;
            width: -webkit-fill-available;
            grid-gap: 16px;
            gap: 16px
      }
          }
.EmailSubscriptionSection__content-container_title {
            font-size: 22px;
            font-weight: 600;
            line-height: 25.78px;
            letter-spacing: -0.01em;
            text-align: left;
        }
.EmailSubscriptionSection__content-container_subtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            text-align: left;
        }
.EmailSubscriptionSection__content-container_discription {
            font-size: 13px;
            font-weight: 500;
            line-height: 15.23px;
            text-align: left;
            color: rgba(0, 0, 0, 0.51);
            color: var(--tone-500);
        }
.EmailSubscriptionSection__subscription-form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        grid-gap: 24px;
        gap: 24px;
      }
@media only screen and (max-width: 767px) {
.EmailSubscriptionSection__subscription-form {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            grid-gap: 16px;
            gap: 16px
      }
          }
.EmailSubscriptionSection__subscription-form_input-container {
            position: relative;
        }
.EmailSubscriptionSection__subscription-form_icon {
            position: absolute; 
            margin-top: 10px;
            margin-left: 12px;
        }
@media only screen and (max-width: 767px) {
.EmailSubscriptionSection__subscription-form_icon {
                margin-top: 12px
        }
              }
.EmailSubscriptionSection__subscription-form_input {
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1;
            width: 367px;
            height: 44px;
            padding: 9px 4px 12px 40px; 
            border: 1px solid rgba(0, 0, 0, 0.11); 
            border: 1px solid var(--tone-300);
            border-radius: 8px;
            background-color: rgba(34, 204, 216, 0.01);
        }
@media only screen and (max-width: 767px) {
.EmailSubscriptionSection__subscription-form_input {
                height: 48px;
                width: 100%
        }
              }
.EmailSubscriptionSection__subscription-form_input::-webkit-input-placeholder {
                color: rgba(0, 0, 0, 0.28);
                color: var(--tone-400);
                font-size: 13px;
                font-weight: 500;
                line-height: 28.2px;
              }
.EmailSubscriptionSection__subscription-form_input::-moz-placeholder {
                color: rgba(0, 0, 0, 0.28);
                color: var(--tone-400);
                font-size: 13px;
                font-weight: 500;
                line-height: 28.2px;
              }
.EmailSubscriptionSection__subscription-form_input:-ms-input-placeholder {
                color: rgba(0, 0, 0, 0.28);
                color: var(--tone-400);
                font-size: 13px;
                font-weight: 500;
                line-height: 28.2px;
              }
.EmailSubscriptionSection__subscription-form_input::-ms-input-placeholder {
                color: rgba(0, 0, 0, 0.28);
                color: var(--tone-400);
                font-size: 13px;
                font-weight: 500;
                line-height: 28.2px;
              }
.EmailSubscriptionSection__subscription-form_input::placeholder {
                color: rgba(0, 0, 0, 0.28);
                color: var(--tone-400);
                font-size: 13px;
                font-weight: 500;
                line-height: 28.2px;
              }
@media only screen and (max-width: 767px) {
.EmailSubscriptionSection__subscription-form_input::-webkit-input-placeholder {
                    font-size: 15px
              }
.EmailSubscriptionSection__subscription-form_input::-moz-placeholder {
                    font-size: 15px
              }
.EmailSubscriptionSection__subscription-form_input:-ms-input-placeholder {
                    font-size: 15px
              }
.EmailSubscriptionSection__subscription-form_input::-ms-input-placeholder {
                    font-size: 15px
              }
.EmailSubscriptionSection__subscription-form_input::placeholder {
                    font-size: 15px
              }
                  }
.EmailSubscriptionSection__subscription-form_input:focus {
                border-color: rgba(0, 0, 0, 0.76);
                border-color: var(--tone-700); 
                outline: none; 
              }
.EmailSubscriptionSection__subscription-form_button {
            width: 200px;
        }
@media only screen and (max-width: 767px) {
.EmailSubscriptionSection__subscription-form_button {
                height: 48px;
                width: auto
        }
              }