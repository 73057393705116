.ProductGalleryFader {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;

  /**
   * Amount of seconds for showing slide
   * Initial value, rewrites in JS code of component from props
   */
  --show-timeout: 6s;
}

  .ProductGalleryFader__img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background-size: cover;
    background-position: center;
    -o-object-fit: cover;
       object-fit: cover;
    opacity: 0;
    -webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
    transition: opacity 1s ease, -webkit-transform 1s ease;
    transition: opacity 1s ease, transform 1s ease;
    transition: opacity 1s ease, transform 1s ease, -webkit-transform 1s ease;
  }

  .ProductGalleryFader__img--animate-scaling {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
      -webkit-transition: opacity 0.7s ease, -webkit-transform 6s ease-in-out;
      transition: opacity 0.7s ease, -webkit-transform 6s ease-in-out;
      transition: opacity 0.7s ease, transform 6s ease-in-out;
      transition: opacity 0.7s ease, transform 6s ease-in-out, -webkit-transform 6s ease-in-out;
    }

  .ProductGalleryFader__img--current {
      opacity: 1;
      z-index: 1;
    }
