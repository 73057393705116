@-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
  }
  
  @keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
  }
  
  .chevron-icon {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.7s ease;
    transition: -webkit-transform 0.7s ease;
    transition: transform 0.7s ease;
    transition: transform 0.7s ease, -webkit-transform 0.7s ease;
  }
  
  .chevron-icon--down {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  
  .chevron-icon--up {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  