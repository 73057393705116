@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Calendar {
  position: relative;
}
.Calendar .rdp {
    --rdp-cell-size: 40px;
    --border-spacing: 5px;
    --rdp-accent-color: var(--accent);
    --rdp-background-color: rgba(34, 204, 216, 0.1);
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: var(--accent);
    --rdp-background-color-dark: rgba(34, 204, 216, 0.1);
    position: relative;

    font-family: "Roboto";
    /* font-family: inherit; */
    line-height: 21px;
    margin: 16px 0 0 0;
    color: rgba(0, 0, 0, 0.98);
    color: var(--tone-900);
    text-transform: capitalize;
  }
.Calendar .rdp-table {
      margin: 20px 0 0;
      border-spacing: var(--border-spacing);
      max-width: calc(var(--rdp-cell-size) * 7 + (var(--border-spacing) * 6));
      border-collapse: unset;
    }
.Calendar .rdp-head_cell {
      font-family: inherit;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      color: var(--dark-gray);
      text-transform: unset;
    }
.Calendar .rdp-cell {
      overflow: hidden;
      position: relative;
    }
.Calendar .rdp-months {
      grid-gap: 24px;
      gap: 24px;
    }
.Calendar .rdp-months[\:has\(\:only-child\)] {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin: 0 -10px;
      }
.Calendar .rdp-months:has(:only-child) {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin: 0 -10px;
      }
.Calendar .rdp-month {
      margin: 0;
    }
.Calendar .rdp-month:only-child {
        width: 100%;
        max-width: 450px;
      }
.Calendar .rdp-month:only-child .rdp-nav {
          position: absolute;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          margin: 0;
          z-index: 1;
        }
.Calendar .rdp-month:only-child .rdp-table {
          width: 100%;
          max-width: 100%;
        }
.Calendar .rdp-month:only-child .rdp-caption_label {
          width: 100%;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
        }
.Calendar .rdp-caption {
      position: relative;
      margin: 0 8px;
    }
.Calendar .rdp-day,
    .Calendar .rdp-caption_label {
      font-family: inherit;
      font-weight: 500;
      font-size: 18px;
      margin: auto;
      z-index: 0;
      overflow: unset;
    }
.Calendar .rdp-day_range_middle {
      border-radius: 50%;
      background-color: var(--rdp-background-color);
      color: rgba(0, 0, 0, 0.98);
      color: var(--tone-900);
    }
.Calendar .rdp .rdp-day_selected {
      border-radius: 50% !important;
    }
.Calendar .rdp-day_outside,
    .Calendar .rdp-day_disabled {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.28);
      color: var(--tone-400);
      pointer-events: none;
      opacity: 1 !important;
    }
.Calendar__day-caption {
    position: absolute;
    color: rgba(0, 0, 0, 0.65);
    color: var(--tone-600);
    font-size: 8px;
    bottom: -1em;
  }
.rdp-day_disabled .Calendar__day-caption {
      color: rgba(0, 0, 0, 0.28);
      color: var(--tone-400);
    }
.Calendar .SpinnerOverlay {
    margin-top: 16px;
  }
