@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Rating {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size:  16px;
  font-weight: 600;
  line-height: 1.2;
}
.Rating__filled-star {
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
.Rating__empty-star {
    position: absolute;
    z-index: 1;
    top: 0;
  }
.Rating__stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -3px;
  }
.Rating__stars_overall {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      margin-left: 0;
    }
.Rating__stars_container{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 50px;
      height: 24px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border-radius: 4px;
      -webkit-box-shadow: 0px 8px 4px -5px rgba(34, 204, 216, 0.25);
              box-shadow: 0px 8px 4px -5px rgba(34, 204, 216, 0.25);
      background-color: #22ccd8;
      background-color: var(--accent); 
    }
.Rating__stars_overall-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 116px;
      height: 66px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 10px;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      border-radius: 8px;
      -webkit-box-shadow: 0px 10px 31px 0px rgba(34, 204, 216, 0.5);
              box-shadow: 0px 10px 31px 0px rgba(34, 204, 216, 0.5);
      background-color: #22ccd8;
      background-color: var(--accent); 
    }
.Rating__item {
    position: relative;
  }
.Rating__item:not(:last-child) {
      margin-right: 2px;
    }
.Rating__grade,
  .Rating__count {
    /* margin-left: 5px; */
    color: white;
    color: var(--white);
  }
.Rating__grade_overall, .Rating__count_overall {
      font-family: Roboto;
      color: white;
      color: var(--white);
      font-size: 44px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.01em;
      text-align: center;

    }
.icon-reviews-gray path {
  fill: rgba(0, 0, 0, 0.51);
  fill: var(--tone-500)
}
.Rating--small {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 16px;
  line-height: 1.2;
}
.Rating--medium .Rating__grade {
      margin-left: 5px;
      color: black;
      color: var(--black);
    }
.Rating--large .Rating__count {
      color: rgba(0, 122, 255, 1);
      color: var(--blue);
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      margin-left: 11px;
    }
.Rating--large .Rating__count--black {
        font-size: 22px;
        font-weight: 600;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.98);
        color: var(--tone-900);
      }
.Rating--large .Rating__count--gray {
        color: rgba(0, 0, 0, 0.51);
        color: var(--tone-500);
      }
.Rating--large .Rating__count_icon {
        margin-right: 6px;
      }
@media only screen and (max-width: 767px) {
.Rating--large .Rating__count {
        margin-left: 20px
    }
      }
.Rating--large .Rating__grade {
      /* margin-left: 5px; */
      color: white;
      color: var(--white);
    }
.Rating--light-main {
  padding: 3px 6px;
  background: #e7eafb;
  background: var(--light-main);
  border-radius: 10px;
  font-weight: 600;
}
.Rating--unfocused .Rating__count {
      color: #979797;
      color: var(--medium-gray);
      font-size: 14px;
      font-weight: 400;
      margin-left: 5px;
    }
.OverallRating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-right: 15px;
}
@media only screen and (max-width: 767px) {
.OverallRating {
    margin-right: 0;
    margin-bottom: 20px
}
  }
.OverallRating__title {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 25.78px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 8px;
  }
.OverallRating__main {
    display: grid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    grid-gap: 10px;
    gap: 10px;
  }
.OverallRating__details {
    display: grid;
  }
.OverallRating__bars {
    width: 100%;
  }
.OverallRating__bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
.OverallRating__bar-background {
    background-color: rgba(0, 0, 0, 0.11);
    height: 4px;
    width: 100%;
    position: relative;
    margin-right: 8px;
    border-radius: 10px;
  }
.OverallRating__bar-fill {
    background-color: #22ccd8;
    background-color: var(--accent);
    height: 4px;
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
  }
.OverallRating__bar-text {
    margin-right: 8px;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.86);
    color: var(--tone-800);
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: right;

  }
.OverallRating__bar-star {
    color: rgba(0, 0, 0, 0.76);
    color: var(--tone-700);
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;

  }

