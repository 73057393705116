.StackedAccordions__header {
    margin-top: 60px;
    margin-bottom: 30px;
    font-weight: 700;
  }
  .StackedAccordions__title {
    font-weight: 600;
    font-size: 18px;
    max-width: 100%;
    word-break: break-all;
  }
  .StackedAccordions__text {
    margin: -10px 0 20px;
    font-weight: 400;
    font-size: 16px;
    display: grid;
    grid-row-gap: 0.7em;
    row-gap: 0.7em;
    max-width: 100%;
    word-break: break-all;
  }
  .StackedAccordions__text > ul {
      margin-left: 1em;
    }
  .StackedAccordions__text > ol {
      margin-left: 1em;
    }
  .StackedAccordions__accordion {
    border-bottom: 1px solid var(--gray-border);
  }
  .StackedAccordions__accordion > button {
      margin: 20px 0;
    }
  .StackedAccordions__accordion .Accordion__title {
      padding-left: 0;
    }
  .StackedAccordions__accordion .Accordion__content {
      padding: 0;
    }
  .StackedAccordions__accordion:last-child {
    border: 0;
    margin-bottom: 60px;
  }
