.InputLabel {
  line-height: 1.5;
  color: var(--text-secondary);
}

  .InputLabel--margin {
    margin-bottom: 5px;
  }

  .InputLabel--disabled {
    color: var(--text-disabled);
  }

  .InputLabel--inside {
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    position: absolute;
    z-index: 1;
    top: -0.5em;
    left: 12px;
    margin: 0;
    padding: 0 4px;
    font-size: 12px;
    line-height: 1em;
  }

  .InputLabel--inside.InputLabel--error {
      color: var(--red);
      background-color: var(--white);
    }

  .InputLabel--inside ~ .InputHelperText {
      margin: 3px;
      font-size: 12px;
      line-height: 1em;
    }
