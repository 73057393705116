.Price--withTip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

  .Price--withTip .Price__price {
      font-weight: 700;
      text-align: right;
      font-size: 22px;
    }

  /* &__solid-line {
      position: relative;
    }
    
    &__solid-line::before {
      content: "";
      position: absolute;
      top: 60%;
      left: 0;
      width: 100%;
      height: .8px;
      background-color: var(--light-text-gray);
      transform: translateY(-50%);
    } */

  .Price--withTip .Price__annotation {
      color: var(--medium-gray);
      font-size: 11px;
      line-height: 1.2;
      white-space: nowrap;
    }

.Price--withTip.Price--inline {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.Price--withTip.Price--inline .Price__exprice {
      margin-left: 6px;
        position: relative;
      }

.Price--withTip.Price--inline .Price__exprice::before {
        content: "";
        position: absolute;
        top: 60%;
        left: 0;
        width: 100%;
        height: .8px;
        background-color: var(--light-text-gray);
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
      }

.Price--withTip.Price--inline .Price__annotation {
      margin-left: 6px;
      line-height: 1.55;
    }

.Price--withTip.Price--capitalized-price .Price__price {
      text-transform: capitalize;
    }

.Price--withTip.Price--capitalized-annotation .Price__annotation {
      text-transform: capitalize;
    }
