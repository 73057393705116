.CategoriesFilter {
  height: 40px;
}

  @media (--tablet), (--mobile) {.CategoriesFilter {
    padding-left: 0;
    border-left: none
}
  }

  .CategoriesFilter__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 0 10px 0;
    grid-gap: 8px;
    gap: 8px;
    position: relative;
  }

  .CategoriesFilter__list > a {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }

  .CategoriesFilter .Button__text {
    font-size: 13px;
  }
